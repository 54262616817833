































































































































.climatescience-sidebar {
  @apply pt-3;

  .section-navigation {
    @apply py-3;
  }

  p + h3 {
    @apply pt-8;
  }

  article {
    .teaser + p {
      @apply pt-0;
    }
  }
}
