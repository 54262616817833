















































.video-icon {
  display: inline;
  margin-left: 0.5rem;
  cursor: pointer;
  fill: #c5e7cc;

  .active {
    fill: #34b44d;
  }

  svg {
    display: inline;
  }
}

svg {
  margin-top: -5px;
  width: 24px;
  height: 24px;
}
